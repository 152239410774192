import dynamic from "next/dynamic";
import FontAwesomeSvg from "./Fontawesome";

const Board = dynamic(() => import("./Board"));
const Browser = dynamic(() => import("./Browser"));
const Chrome = dynamic(() => import("./Chrome"));
const Safari = dynamic(() => import("./Safari"));
const School = dynamic(() => import("./School"));
const Slides = dynamic(() => import("./Slides"));
const Class = dynamic(() => import("./Class"));
const Counseller = dynamic(() => import("./Counseller"));
const DailyClasses = dynamic(() => import("./DailyClasses"));
const DOB = dynamic(() => import("./DOB"));
const DoubtSolving = dynamic(() => import("./DoubtSolving"));
const Edge = dynamic(() => import("./Edge"));
const EducationBook = dynamic(() => import("./EducationBooks"));
const Exam = dynamic(() => import("./Exam"));
const Firefox = dynamic(() => import("./Firefox"));
const HomeWork = dynamic(() => import("./HomeWork"));
const Info = dynamic(() => import("./Info"));
const Learner = dynamic(() => import("./Learner"));
const Notes = dynamic(() => import("./Notes"));
const PaidStudent = dynamic(() => import("./PaidStudent"));
const PlayIcon = dynamic(() => import("./PlayIcon"));
const QuestionMark = dynamic(() => import("./QuestionMark"));

const SvgComponentObj: {
  [type: string]: any
} = {
  DailyClasses,
  DoubtSolving,
  HomeWork,
  Notes,
  PlayIcon,
  Slides,
  Class,
  Board,
  Exam,
  School,
  DOB,
  Info,
  Counseller,
  QuestionMark,
  PaidStudent,
  Learner,
  EducationBook,
  Chrome,
  Edge,
  ["Mobile Safari"]: Safari,
  Firefox,
  Browser
}

const IconGeneric = (props: { name: string, className?: string }) => {
  if (props.name in SvgComponentObj) {
    return SvgComponentObj[props.name]?.render?.({ className: props?.className });
  }
  return FontAwesomeSvg({ name: props.name, className: props.className });
}

export default IconGeneric;