import FaCartShopping from "./FaCartShopping"
import FaCheckDouble from "./FaCheckDouble"
import FaClock from "./FaClock"
import FaEye from "./FaEye"
import FaFaceLaughBeam from "./FaFaceLaughBeam"
import FaGooglePlay from "./FaGooglePlay"
import FaGraduationCap from "./FaGraduationCap"
import FaMedal from "./FaMedal"
import FaRankingStar from "./FaRankingStar"
import FaVideo from "./FaVideo"
import FaYoutube from "./FaYoutube"
import FaUser from "./FaUser"
import FaCamera from "./FaCamera"
import FaCirclePlay from "./FaCirclePlay"
import FaDownload from "./FaDownload"
import FaBook from "./FaBook"
import FaHouseChimney from "./FaHouseChimney"


const FontAwesomeObj:{
    [type:string]:any
} = {
  FaEye,
  FaCheckDouble,
  FaFaceLaughBeam,
  FaGooglePlay,
  FaGraduationCap,
  FaCartShopping,
  FaRankingStar,
  FaClock,
  FaMedal,
  FaVideo,
  FaYoutube,
  FaUser,
  FaCamera,
  FaCirclePlay,
  FaDownload,
  FaBook,
  FaHouseChimney
}

const FontAwesomeSvg = (props: { name: string, className?: string }) => {
  return FontAwesomeObj[props.name]?.render?.({className: props.className});
}

export default FontAwesomeSvg;